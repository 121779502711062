.float-menu {
  position: fixed;
  width: 40px;
  height: 30px;
  /* bottom: 10px; */
  z-index: 999;
  top: 90px;
  background-color: #fff;
  color: #fff;
  border-radius: 50px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  text-align: center;
  box-shadow: 1px 1px 5px 0px #999;
  transition: all 0.2s;
}

.collaps-btn {
  color: #002140;
  font-size: 22px;
}

.affix__wrapper .ant-affix {
  top: unset !important;
}

/* side nav for admin */
.sidenav__admin {
  background: #ffffffee;
  overflow: hidden;
  height: 100%;
  z-index: 999;
}

.admin__client_table .ant-table.ant-table-small .ant-table-container {
  /* min-width: 900px; */
}
/* .admin__client_table .ant-table.ant-table-small:hover {
  overflow-y: auto;
  -ms-overflow-style: auto;
} */
.ant-table-cell-scrollbar {
  display: none;
}

/* filter */

.ssrie__filter_set {
  width: 100%;
}
.ssrie__filter {
  width: 170px;
}
.ssrie__filter_space {
  width: 100%;
}
.ssrie__filter_space .ant-space-item:nth-child(1) {
  width: 100%;
}

/* ---- */
